.checkbox {
    &__label{
        display: flex;
        align-items: center;
    }
    &__text {
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: #666;
        margin-left: 5px;
        user-select: none;
    }   
}