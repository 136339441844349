.scrollable {
    height: calc(100vh - 210px);
    overflow-y: auto;

    @media screen and (max-width: 991px) {
        height: calc(100vh - 180px);
    }
}

.infinite-scroll {
    overflow: visible !important;

    &__spinner {
        text-align: center;
    }
}

.list-table {
    border: 0px solid coral;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
    overflow-x: hidden;

    &__header {
        position: sticky;
        top: 0px;
        z-index: 3;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 1px #C1C1C1;

        &__item {
            font-family: "Roboto", sans-serif;
            color: #555;
            font-size: 14px;
            font-weight: 400;
            padding-bottom: 10px;
            padding-top: 5px;
            padding-left: 5px;
            position: relative;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            user-select: none;

            &__sort {
                margin-left: 7px;
                display: inline-block;
                border: 0px solid coral;

                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;
                    ;
                }
            }

            &__resizer {
                position: absolute;
                border: 1px solid #EEEEEE;
                background-color: #EEEEEE;
                right: 5px;
                height: 25px;
                width: 1px;
                top: 0;
                touch-action: none;
            }

            &:last-child>&__resizer {
                display: none;
            }

            &:last-child {
                position: relative;
                z-index: 2;
            }

            &:nth-child(1) {
                background-color: #FFFFFF;
            }
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__body {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #66686C;
        border: 0px solid coral;
        display: block;

        &__row {
            border-bottom: 1px solid #F9F9F9;

            &__cell {
                padding: 13.5px 0px;
                padding-left: 5px;
                border: 0px solid #F9FAFA;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;


                &__header {
                    display: none;

                    // @media screen and (max-width: 991px) {
                    //     display: block;
                    //     font-family: "Roboto", sans-serif;
                    //     font-weight: 500;
                    //     text-align: left; 
                    // }
                }

                &__data {
                    display: inline;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                }

                // &:first-child >&__header {

                //     @media screen and (max-width: 991px) {
                //         display: none;
                //     }
                // }

                // &:first-child >&__data {
                //     pointer-events: auto;

                // }

                // &:first-child {
                //     pointer-events: none;
                // }

                // &:nth-child(2) {
                //     color: #687178
                // }

                // &:nth-child(1)>&__header {

                //     @media screen and (max-width: 991px) {
                //         display: none;
                //     }

                // }

                &:nth-child(1) {
                    font-weight: 500;
                    color: #1F2747;
                    background-color: #FFFFFF;

                    @media screen and (max-width: 991px) {
                        font-weight: 500;
                        background-color: transparent;
                    }
                }

                // &:last-child {
                //     overflow: visible;
                //     text-align: right;

                //     @media screen and (max-width: 991px) {
                //         border: 0px;
                //     }
                // }

                // &:last-child>&__header {
                //     display: none;
                // }

               

                @media screen and (max-width: 991px) {
                    padding: 0px 5px;
                    display: flex;
                    justify-content: space-between;
                    width: 100% !important;
                    border-bottom: 0px solid #DFDFDF;
                    line-height: 23px;
                }

                &--right-aligned-mobile-cell{
                
                    @media screen and (max-width: 991px) {
                        position: absolute;
                        bottom: 10px;
                        right: 15px;
                        z-index: 3;
                        padding: 0px 0px;
                    }
                }
            }

            &__mobile-icon{
                display: none;

                @media screen and (max-width: 991px) {
                    display: block;
                    position: absolute;
                    top:10px;
                    right: 15px;
                    color: #918e8e;
                    font-size: 12px;
                }
            }

            &:hover {
                background-color: #F9FAFA;
                cursor: pointer;
            }

            &:hover &__cell:nth-child(1) {
                background-color: #F9FAFA;

                @media screen and (max-width: 991px) {
                    // background-color: #F1F1F1;
                }
            }

            &:nth-child(even) {
                // background-color: #F9FAFA;

                @media screen and (max-width: 991px) {
                    // background-color: #F1F1F1;
                }
            }

            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                width: 100% !important;
                padding: 11px 5px;
                border-bottom: 15px solid #F3F3F3;
                position: relative;
            }
        }

        @media screen and (max-width: 991px) {
            display: block;
            width: 100% !important;
        }
    }

    @media screen and (max-width: 991px) {
        display: block;
        width: 100% !important;
    }
}

