.home-nav{
    width: 100%;
    padding: 10px 10px;
    padding-bottom: 10px;
    background-color: #FFFFFF;
    border-radius: 30px;
    margin-top: 20px;
    border: 1px solid #EDEDED;

    &__single {
        padding: 20px 20px;
    }

    &__chart {
        padding: 0px !important;
    }
}