.date-picker-inline {
    border: 0px solid coral;
    display: flex;
    padding: 3px 10px;
    // align-items: center;

    &__label {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #333C44;
    
        &__required {
            color: #D51B15;
        }
    }

    &__input {
        margin-left: 10px;
        border: 1px solid #FFFFFF;
        height: 2px;
        max-width: 90px;
        background-color: #FFFFFF;
        text-indent: 10px;
        border-radius: 7px;
        font-size: 13px;

        &:focus {
            outline: 0px solid #3084B7;
        }

        &--error {
            border: 1px solid #D51B15;
        }
        
        &--disabled{
            background-color: #F9F9F9;

            &:hover{
                cursor:not-allowed;
            }
        }
    }

    &__date-view {
        width: 100%;
    }
}
