.date-picker {
    display: flex;
    flex-direction: column;
    padding: 3px 0px;

    &--sideBySide{
        flex-direction: row;
        gap: 10px;
    }

    &__label {
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        color: #333C44;
        white-space: nowrap;
        border: 0px solid coral;

        &__required {
            color: #D51B15;
        }
    }

    &__input {
        border: 1px solid #E9E9E9;
        height: 33.5px;
        background-color: #FAFAFA;
        text-indent: 10px;
        border-radius: 10px;
        font-size: 14px;
        width:100%;

        &:focus {
            outline: 2px solid #3084B7;
        }

        &--error {
            border: 1px solid #D51B15;
        }
        
        &--disabled{
            background-color: #FAFAFA;
            color: #333;

            &:hover{
                cursor:not-allowed;
            }
        }
    }

    &__date-view {
        width: 100%;
    }
}

.react-datepicker {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    color: #000;
    border: 0px;
    box-shadow: 0px 0px 10px #D1D1D1;
    border-radius: 10px;
    z-index: 9999;
}

.react-datepicker__header {
    background-color: #FFF;
    border-bottom: 1px solid #E9E9E9;
}

.react-datepicker__today-button {
    background: #FFF;
    border-top: 1px solid #E9E9E9;
}