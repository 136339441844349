.custom-button{
    width: auto;
    height: 30px;
    padding: 0px 13px;
    color: #FFF;
    background-color: #0095F6;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    border:1px solid #0095F6;
    border-radius: 15px;

    &__icon{
        margin-right:5px;
        margin-top:-1px;
        font-size: 16px;
        color: #FFFFFF;

        &--inverted{
            color: #999;
        }
    }

    &--inverted{
        background-color: #FFF;
        color: #687178;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 0px 3px #999;
    }

    &--disabled{
        background-color: #A1CEFF  !important;
        border:0px;
        color: #FFF;
        box-shadow: 0px 0px 0px;
    }
}