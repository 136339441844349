.table-cell-status{
    font-family: "Robot", sans-serif;
    font-size: 13px;
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 500;

    &--danger{
        background-color: #FFF5F0;
        color: #CE5A63;
    }

    &--warning{
        background-color: #fdfced;
        color: #f77402;
    }

    &--success{
        background-color: #EAF5EE;
        color: #286840;
    }
}