.log-in-custom-button{
    width: 100%;
    height: 53px;
    color: #FFF;
    background-color: #0095F6;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    border:1px solid #0095F6;
    border-radius: 4px;

    &--disabled{
        background-color: #61B5EB;
    }
}