.form-table-select {
    display: flex;
    flex-direction: column;
    padding: 0px 0px;

    @media screen and (max-width: 991px) {
        &--mobile {
            display: flex;
            flex-direction: column;
            border: 0px solid coral;
            background-color: #FFF;
            padding: 2px 0px;
            padding-top:5px;
        }

        &__label {
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            color: #798086;
            margin-bottom: 0px;
            padding-left: 10px;

            &__required {
                color: #D51B15;
            }
        }

        &__input {
    
        }
    }



    &__select {
        

        &--error{
            border: 1px solid #D51B15;
        }
    }
}
