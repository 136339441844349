.form-textarea{
    display: flex;
    flex-direction: column;
    padding:5px 0px;

    &__label{
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        color: #333C44;
        text-transform: capitalize;

        &__required{
            color: #D51B15;
        }
    }
    &__input{
        border: 1px solid #F9FAFA;
        height: 65px;
        background-color: #F9FAFA;
        text-indent:10px;
        border-radius: 5px;
        font-size: 14px;
    }
    &__input:focus{
        outline: 2px solid #3084B7;
    }
}