
.action-drop-down{
    width: 107px;
    border: 1px solid #C1C9D2;
    background-color: #FFFFFF;
    position: absolute;
    right:0;
    list-style: none;
    min-width:180px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #999;
    padding: 5px 0;

    &--active{
        display: block;
    }

    &__item{
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        padding: 0 4px;
        color: #16325C;
        font-size: 14px;
        border: 0px solid coral;
        display: flex;
        align-items: center;

        &__icon{
            padding-left: 10px;
        }

        &__btn{
            padding: 7px 10px;
            width: 100%;
            background-color: transparent;
            border: 0px solid coral;
            text-align: left;
            border-radius: 5px;
            text-transform: capitalize;
        }

        &:hover{
            cursor: pointer;
            background-color: #F4F5F6;
        }
    }

    &--small{
        top: 23px;
    }

    &--medium{
        top: 33px;
    }
}