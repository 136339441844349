* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: "Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;;
  font-size:16px;
  background-color: #F3F3F3 !important;
  letter-spacing: 0.3px;
  -webkit-user-scalable: none;
  line-height: 1.4
}

a {
  text-decoration: none;
}

