.form-table-container {
    border-bottom: 1px solid #F3F6FC;
    padding: 20px;
}

.form-table-header {
    font-family: "Robot", sans-serif;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    &__title-action {
        width: 100%;
        border: 0px;
        background: transparent;
        display: flex;

        &__title {
            font-family: "Roboto", sans-serif;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            pointer-events: none;
        }

        &__img {
            margin-top: 6px;
            margin-left: 10px;
            pointer-events: none;

            &--expand {
                transform: rotate(180deg);
            }
        }
    }
}

.form-table-body {
    &--collapse {
        display: none !important;
    }

    &--expand {
        display: block;
    }

    &__separator {
        padding: 15px 0px;
    }
}

.form-table-wrapper {
    width: 100%;
    overflow-x: auto;
    border: 0px solid #F3F6FC;
    border-bottom: 0px;
    border-radius: 7px 7px 0px 0px;
    margin-bottom: 0px;

    @media screen and (max-width: 991px) {
        border: 1px solid #E9E9E1;
    }
}

.form-table {
    width: 100%;
    border-collapse: collapse;

    &__header {
        top: 0px;
        border-bottom: 1px solid #EFEFEF;
        background-color: #EFEFEF;
        border-radius: 10px;

        &__item {
            font-family: "Roboto", sans-serif;
            font-size: 13px;
            padding: 10px;
            color: #454545;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: 400;
            user-select: none;
            border-radius: 10px;

            &__sort {
                margin-left: 7px;
                display: inline-block;
                border: 0px solid coral;

                &__icon {
                    border: 0px solid coral;
                    margin-top: -3px;
                    ;
                }
            }

            &:last-child>&__resizer {
                display: none;
            }
        }

        &--inverted {
            background-color: #FFFFFF;
        }

        @media screen and (max-width: 991px) {
            display: none;
        }
    }

    &__body {
        font-family: "Roboto", sans-serif;
        background-color: #EFEFEF;
        font-size: 15px;
        color: #1F2747;
        border: 0px solid coral;
        padding-bottom: 5px;
        display: block;


        &__row {
            border: 5px solid #FAFAFA;

            // &:hover {
            //     background-color: #FAFAFA;
            // }

            &__cell {
                padding: 0px;
                border-right: 1px solid #EBEEF0;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:empty{
                    display: none !important;
                }

                &__header {
                    display: none;

                    @media screen and (max-width: 991px) {
                        // display: block;
                        // font-family: "Roboto", sans-serif;
                        // font-weight: 500;
                        // text-align: left;
                        // border: 0px solid coral;
                    }
                }

                &__data {
                    display: inline;
                    font-family: "Roboto", sans-serif;
                    font-size: 14px;
                    border: 0px solid coral;

                    &__data:empty{
                        border: 1px solid green;
                    }
                }


                &:last-child.form-table__body__row__cell {
                    &--action {
                        padding: 7px;
                        padding-left: 10px;
                        border-right: 0px;
                    }
                }

                @media screen and (max-width: 991px) {
                    display: block;
                    border-right: 0px;
                    margin-top: 10px;

                    &:first-child {
                        min-width: 100% !important;
                    }

                    &:not(:first-child) {
                        min-width: 50% !important;
                        border-right: 1px solid #EFEFEF; 
                    }

                    // &:not(:first-child):nth-child(even) {
                    //    border-right: 5px solid #EFEFEF; 
                    // }
                    // &:not(:first-child):nth-child(odd) {
                    //     border-left: 5px solid #EFEFEF; 
                    //  }

                    &:last-child {
                        display: flex;
                        justify-content: right;
                        align-items: center;
                    }
                }
            }

            &:nth-child(even) {

                @media screen and (max-width: 991px) {
                    background-color: #ECECEC;
                }
            }

            @media screen and (max-width: 991px) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin-bottom: 15px;
                border-bottom: 0px;
                padding: 5px;
                border: 0px solid #FAFAFA;

            }
        }

        @media screen and (max-width: 991px) {
            display: block;
            width: 100% !important;
        }
    }

    @media screen and (max-width: 991px) {
        display: block;
        width: 100% !important;
    }
}

.form-table-footer {
    border: 0px solid coral;

    &__additional {
        border: 0px solid coral;
        display: flex;
        justify-content: space-between;

    }
}

.react-table-column-flex-grow-1 {
    flex-grow: 1 !important;
    width: unset !important;
    flex-basis: 5px !important;
    max-width: none !important;
}

.form-table-total-wrapper {
    border: 0px solid coral;
}

.form-table-external-fields-wrapper {
    border: 0px solid coral;
    width: 100%;
}

