.log-in-form{
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 30px;
    border:1px solid #E6EBF1;
    padding:35px 7px;

    &__buttons{
        margin: 0 22px;
        margin-top: 10px;
    }
    &__radio{
        margin: 0 22px;
        margin-top: 22px;
    }
    &__general-error{
        margin: 0 22px;
    }
    &__input-error{
        margin: 0 22px;
    }

    @media screen and (max-width: 991px) {
        padding:35px 0px;
    }
}