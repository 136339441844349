.form-body{
    display: flex;
    justify-content: right;
    border:0px solid coral;
    position: relative;
    max-width: 1040px;
    margin: 0 auto;
  
    &__wrapper{
        border:1px solid #EDEDED;
        width: 100%;
        padding: 0px 0px;
        background-color: #FFFFFF;
        border-radius: 30px;
        min-height: 100px;
        margin-bottom: 20px ;

        @media screen and (max-width: 991px) {
            width: 100%;
            border-radius: 0px;
            margin-bottom: 70px ;
        }
    }

    &__spinner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    @media screen and (max-width: 991px) {
        justify-content: center;
    }

}