.account-display {
    display: flex;

    &__icon {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: #EF6C00;
        color: #FFFFFF;
        text-align: center;
        padding: 2px;
        text-transform: capitalize;

        @media screen and (max-width: 991px) {
            width: 25px;
            height: 25px;
            padding: 0px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-left: 10px;

        &__username {
            font-family: "Open Sans", "Roboto", sans-serif;
            font-size: 13px;
            font-weight: 600;
            border: 0px solid coral;
            line-height: 14px;

        }

        &__email {
            font-family: "Open Sans", "Roboto", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 13px;

            @media screen and (max-width: 991px) {
                display: none;
            }
        }

        @media screen and (max-width: 991px) {
            justify-content: center;
        }
    }

  
}