.form-body-section {
    padding: 20px 10px;
    width: 100%;
    border-bottom: 1px solid #E9E9E1;
    display: flex;
    flex-direction: column;

    &--margin-top-5 {
        margin-top: 5px
    }

    &--last {
        border-bottom: 0px;
    }

    &--noPadding{
        padding: 0px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 7px;

        &__title-action {
            width: 100%;
            border: 0px;
            background: transparent;
            display: flex;
            padding: 0 10px;
            border: 0px solid coral;

            &__title {
                font-family: "Roboto", sans-serif;
                font-size: 15px;
                font-weight: 600;
                text-transform: capitalize;
                pointer-events: none;
            }

            &__img {
                margin-top: 6px;
                margin-left: 10px;
                pointer-events: none;

                &--expand {
                    transform: rotate(180deg);
                }
            }
        }
    }

    &__sub-section {

        &--collapse {
            display: none !important;
        }

        &--expand {
            display: block;
        }
    }

    &__body {
        
        &__sub-header {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            padding: 0 10px;
            border: 0px solid coral;

            &__title {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: 500;
                text-transform: capitalize;
            }
        }

        &__parts {

            &__separator {
                width: 70%;
                margin: 15px auto;
                border-bottom: 1px solid #E9E9E1;
            }

            &__item {
                padding: 5px 10px !important;
                border: 0px solid coral;

                &--noPadding{
                    padding: 0px !important; 
                    padding-right: 20px !important;
                }
            }
        }

        &__buttons {
            border: 0px solid coral;
            width:100%;
            padding-right: 5px;
            display: flex;
            justify-content: right; 
        }
    }

    &__footer {
        border:0px solid coral;
        padding-top: 10px;
        padding-left: 20px;
        display: flex;
        column-gap: 10px;
    }
}