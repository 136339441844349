.list-global-filter{
    background-color: #FFFFFF;
    height: 33px;
    border-radius: 20px;
    display: flex;
    padding: 0px 10px;
    max-width: 450px;
    border: 1px solid #d4d1d1;

    
    &__icon{
        width: 15px;
        pointer-events: none;
    }

    &__field{
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        text-indent: 10px;
        border: 0px;
        background-color: #FFFFFF;
        height: 29px;
        width: 100%;
        border-radius: 20px;
       
        &:focus{
            outline: 0px;
        }
    }

    @media screen and (max-width: 991px) {
        max-width: 100%;
    }

}