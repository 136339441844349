.drop-down-button {
    width: auto;
    height: 30px;
    padding: 0 15px;
    background-color: #FFFFFF;
    border: 0px solid #C1C9D2;
    border-radius: 20px;
    box-shadow: 0 0 4px #C1C9D2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    color: #888;

    &__content {
        margin-right: 8px;

        // &--no-icon {
        //     margin-left: 8px;
        // }
    }

    &__icon{
        font-size: 17px;
        color: #999;

        &--inverted{
            color: #FFF
        }

        &--right-margin{
            margin-right: 5px;
        }
    }

    &--inverted{
        background-color: #0095F6;
        color: #FFF;
    }
}