.form-table-total{
    min-width: 300px;
    background-color: #F1F1F1;
    border-radius: 0px 0px 7px 7px;
    padding: 10px;

    &__single{
        display: flex;
        justify-content: space-between;
        padding: 5px 0px;
        border-top: 0px solid #EFEFEF;

        &:first-child{
            border-top: 0px;
        }

        &__title{
            font-size: 14px;
            color: #454545;
        }

        &__content{
            font-size: 14px;
            font-weight: 500;
            color: #696868;
        }
    }

    &--inverted{
        background-color: #FFFFFF;
    }

    @media screen and (max-width: 991px) {
        min-width: auto;
        width: 210px;
    }
}