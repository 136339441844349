.help-body{
    border: 0px solid coral;
    max-width: 1290px;
    margin: 0px auto;
    padding: 10px 10px;

    &__wrapper{
        padding: 30px 30px;
        padding-bottom: 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow:  0px 0px 4px rgba(25, 39, 52, 0.1);
        margin-top: 10px;
    }

    &__item{
        box-shadow:  0px 0px 2px rgba(25, 39, 52, 0.2);;
        padding: 20px 40px;
        border-radius: 20px;
        margin-bottom: 30px;
    }

    &__error{
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        display: inline-block;
        width: 100%;
        padding: 10px;
        padding-bottom: 30px;
    }

    @media screen and (max-width: 991px) {
        position: relative;
        padding: 0px 10px !important;
        z-index: 2;
        top:50px;
        left: 0px;
    }
}