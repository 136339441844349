.form-full-error{
    width: 98%;
    margin-bottom: 10px;
    padding: 20px;

    &__header{
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-bottom: 1px solid #F4F5F6;
        background-color: transparent;
        border-radius: 7px 7px 0px 0px;
        padding: 5px 5px;
        display: flex;
        justify-content: left;

        &__icon{
            margin-right: 8px;
            margin-top: -1px;
        }
    }

    &__body{
        border-bottom: 1px solid #F4F5F6;
        border-top: 0px;
        width: 100%;
        padding: 10px 5%;
        font-family: "Roboto", sans-serif;
        font-size: 13px;

        &__container{
            margin:0px;
            display: flex;
            flex-direction: column;
            list-style-position: inside;
            
        }
        &__item{
            font-family: "Roboto", sans-serif;
            font-size: 13px;
        }
    }
}