.form-table-calculate{
    @media screen and (max-width: 991px) {
        &--mobile {
            display: flex;
            flex-direction: column;
            border: 0px solid coral;
            background-color: #FFF;
            padding: 2px 0px;
            padding-top:5px;
        }

        &__label {
            font-family: 'Roboto', sans-serif;
            font-size: 13px;
            color: #798086;
            margin-bottom: 0px;
            padding-bottom: 1px;
            padding-left: 10px;

            &__required {
                color: #D51B15;
            }
        }
    }
    
    &__display{
        border-bottom: 1px solid #EFEFEF;
        height: 38px;
        text-align: right;
        padding: 10px;
        background-color: #FFFFFF;  

        @media screen and (max-width: 991px) {
            text-align: left;
            padding: 0px 10px;
            height: 21px;
            font-size: 14px;
        }
    }
}