.title-header{
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 22px;
    display: inline-block;
 

    &__button{
        border: 0px;
        background-color: transparent;
    }

    &__icon{
        border: 0px solid coral;
        margin-right: 12px;
        margin-top: -3px;
        cursor: pointer;

        @media screen and (max-width: 991px) {
            display: none;;
        }
    }

    &__text {
        border: 0px solid coral;
        color: #2F2F2F;
        font-weight: 400;
        margin-top: 5px;

        @media screen and (max-width: 991px) {
            font-size: 20px;
        }
    }

    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
}