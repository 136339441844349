.nav-dropdown {
    position: absolute;
    background-color: #FFF;
    ;
    top: 46px;
    list-style: none;
    border: 0px solid #E0E5EE;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(25, 39, 52, 0.3);
    border: 0px solid coral;

    &--right {
        right: 12px
    }

    &--left {
        left: 12px;
    }

    &--short {
        top: 35px;
    }

    &__item {
        padding: 0 4px;
        display: flex;
        align-items: center;

        &--breakpoint {
            border-top: 1px solid #d5d5d5;
        }

        &__btn {
            padding: 8px 14px;
            width: 100%;
            background-color: transparent;
            border: 0px solid coral;
            text-align: left;
            border-radius: 5px;
        }

        &__btn:hover {
            cursor: pointer;
            background-color: #F4F5F6;
        }

        &__icon {
            width: 14px;
            margin-right: 15px;
            pointer-events: none;

            &--placeholder {
                display: inline-block;
                width: 14px;
                margin-right: 15px;
            }
        }

        &__text {
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-weight: 400;
            color: #1F272E;
            display: inline-block;
            pointer-events: none;

        }
    }

    &__item:first-child {
        margin-top: 15px;
    }

    &__item:last-child {
        margin-bottom: 15px;
    }

    @media screen and (max-width: 991px) {
        position: static;
        background-color: #FFFFFF;
        box-shadow: none;
        padding-left: 20px;

        &__item {
            margin-top: 10px;
            display: flex;
            align-items: center;

            &__btn {
                color: #687178;
            }

            &__btn:hover {
                cursor: pointer;
                background-color: #026aa7;

            }


            &__icon {
                width: 14px;
                margin-right: 15px;
            }

            &__text {
                display: inline-block;

            }
        }
    }
}