.home-nav-item {
    border: 0px solid coral;

    &__header {
        border: 0px solid coral;
        display: flex;
        align-items: center;
        gap: 13px;
        padding: 0px 5px;

        &__icon {
            border: 0px solid coral;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            // background-color: #ecf8ff;
            background-color: #0095F6;
            display: flex;
            justify-content: center;
            align-items: center;

            &__item{
                font-size: 21px;
                color: #FFFFFF;
            }
        }

        &__title {
            font-family: "Roboto", sans-serif;
            font-size: 20px;
            font-weight: 400;
            color: #4A4A4A;
            // text-transform: uppercase;
        }
    }

    &__body {
        margin-top: 15px;
        list-style: none;

        &__item {
            border: 0px solid coral;
            padding: 7px 0px;

            &__link {
                display: flex;
                justify-content: space-between;
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: 400;
                color: #4A4A4A;
                width: 90%;
                gap: 3px;
                padding: 7px 8px;
                border-bottom: 1px solid #FAFAFA;
                border-radius: 0px;

                &:hover {
                    text-decoration: none;
                    color: #1F2747;
                    background-color: #F4F5F6;
                    border-radius: 7px;
                }

                @media screen and (max-width: 991px) {
                    width: 100%;
                }
            }
        }
    }
}