.report-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 15px;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;


    &--sticky {
        background-color: #FFF;
        box-shadow: 0px 0px 4px #E1E1E1;
    }

    &__title {
        flex: 1;
        border: 0px solid coral;

        @media screen and (max-width: 991px) {
            flex: 2;
        }
    }

    &__tools {
        flex: 2;
        display: flex;
        align-items: center;
        gap: 8px;

        &__actions {
            display: flex;
            gap: 8px;
            justify-content: end;
            border: 0px solid coral;
            width: 100%;

            &:last-child {
                margin-right: -8px;
            }

            @media screen and (max-width: 991px) {
                width: 100%;
            }
        }

        @media screen and (max-width: 991px) {
            padding: 0 0px;
        }
    }

    &__spinner {
        margin-right: 15px;
    }

    @media screen and (max-width: 991px) {
        padding: 15px 15px;
        z-index: 2;
    }
}